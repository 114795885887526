/* eslint-disable indent */
import { useEffect, useState } from "react";
// @mui
import {
  Stack,
  Button,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Iconify from "../../components/iconify";
import ConfirmDialog from "../../components/confirm-dialog";
import { useSharedContext } from "../SharedComponentsContext";
import Label from "../../components/label";
import { calculateDealStageProgress } from "../../api/bitrix/status";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { Status } from "../../api/bitrix/types/status";
import { format } from "date-fns";
import { createInvoice } from "../../api/parnerus/services/invoice";
import { dbContact } from "../../api/parnerus/types/contact";
import { dbCompany } from "../../api/parnerus/types/company";
import { dbInvoice } from "../../api/parnerus/types/invoice";
import QuoteDocument from "./QuoteDocument";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import { sendDocument } from "../../api/parnerus/services/sendDocument";
import { useSnackbar } from "../../components/snackbar";
import { useAuthContext } from "../../auth/useAuthContext";
import { dbAccount } from "../../api/parnerus/types/account";
import axios from "../../utils/axios";
import { isInWebView } from "../../utils/reactNative";

// ----------------------------------------------------------------------

type Props = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  taskName: string;
  liked: boolean;
  completed: boolean;
  onLike: VoidFunction;
  onAttach: VoidFunction;
  onCompleted: VoidFunction;
  onCloseDetails: VoidFunction;
  contact: dbContact | undefined;
  company: dbCompany | undefined;
};

export default function QuoteDetailsToolbar({
  fileInputRef,
  taskName,
  liked,
  completed,
  onLike,
  onAttach,
  onCompleted,
  onCloseDetails,
  contact,
  company,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { account } = useAuthContext();
  const {
    quote,
    openQuoteEditSlider,
    openInvoiceViewSlider,
    triggerLoading,
    closeQuoteViewSlider,
    openUpgradeModal,
  } = useSharedContext();
  const { user } = useAuthContext();
  const isDesktop = useResponsive("up", "sm");
  const isMobile = useResponsive("down", "sm");
  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const createInvoiceHandler = async () => {
    if (quote) {
      setIsLoading(true);

      const contact: Partial<dbContact> = {
        contactId: quote.contactId,
        nameSearch: quote.contactName,
      };
      const company: Partial<dbCompany> | undefined = quote.companyId
        ? {
            companyId: quote.companyId,
            titleSearch: quote.companyName,
          }
        : undefined;
      const invoice: dbInvoice = await createInvoice(
        contact,
        company,
        quote.products,
        "New",
        quote.dueDate,
        account?.accountId,
        quote.quoteId
      );
      openInvoiceViewSlider(invoice);

      setIsLoading(false);
    }
  };

  const sendQuoteHandler = async () => {
    if (!contact?.EMAIL) {
      enqueueSnackbar(
        "El cliente seleccionado no tiene correo electrónico. Por favor, edite el contacto y vuelva a intentarlo.",
        { variant: "error" }
      );
      return;
    }

    if (quote) {
      const documentBlob = await ReactPDF.pdf(
        <QuoteDocument
          quote={quote}
          user={user}
          contact={contact}
          company={company}
          account={account as dbAccount}
        />
      ).toBlob();
      sendDocument(
        documentBlob,
        contact.EMAIL,
        `Cotización de ${account?.name}`,
        `Has recibido una cotización de ${account?.name}. Puedes encontrar dicha cotización adjunta en este correo.`,
        account?.accountId
      )
        .then(() => {
          enqueueSnackbar("Cotización enviada.", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar(
            "Hubo un error al enviar la cotización. Por favor, revise la configuración de correo de su cuenta.",
            { variant: "error" }
          );
        });
    }
  };

  const handleDownloadInWebView = async () => {
    if (!quote) {
      return;
    }
    const pdfBlob = await ReactPDF.pdf(
      <QuoteDocument
        quote={quote}
        user={user}
        contact={contact}
        company={company}
        account={account as dbAccount}
      />
    ).toBlob();
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64data = reader.result;
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: "download", data: base64data })
      );
    };

    reader.readAsDataURL(pdfBlob); // Convert blob to base64
  };

  const handleDelete = async (id: string | undefined) => {
    await axios.post("/api/quote/disable", {
      quoteId: id,
    });
    triggerLoading();
    closeQuoteViewSlider();
  };

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        {!isDesktop && (
          <Tooltip title="Back">
            <IconButton onClick={onCloseDetails} sx={{ mr: 1 }}>
              <Iconify icon="eva:arrow-ios-back-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Typography sx={{ mr: 1 }}>
          Cotización #{quote?.quoteId.split("-")[0].toUpperCase()}
        </Typography>

        {quote && !isMobile && (
          <Label
            variant="soft"
            color={
              (quote.status === "Accepted" && "success") ||
              (quote.status === "Sent to customer" && "warning") ||
              (quote.status === "Declined" && "error") ||
              "default"
            }
          >
            {quote.status}
          </Label>
        )}

        {isDesktop && !quote?.enabledItem && (
          <Label
            variant="soft"
            color="error"
            sx={{ textTransform: "capitalize", ml: 2 }}
          >
            Deshabilitada
          </Label>
        )}

        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
        >
          {quote && !isInWebView() && (
            <Tooltip title="Descargar PDF">
              <PDFDownloadLink
                document={
                  <QuoteDocument
                    quote={quote}
                    user={user}
                    contact={contact}
                    company={company}
                    account={account as dbAccount}
                  />
                }
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    enqueueSnackbar(
                      "Cotización descargada. Revise su carpeta de descargas.",
                      { variant: "success" }
                    )
                  }
                >
                  <Iconify icon="teenyicons:pdf-solid" />
                </IconButton>
              </PDFDownloadLink>
            </Tooltip>
          )}
          {quote && isInWebView() && (
            <IconButton size="small" onClick={handleDownloadInWebView}>
              <Iconify icon="teenyicons:pdf-solid" />
            </IconButton>
          )}
          <Tooltip title="Enviar al cliente">
            <IconButton
              size="small"
              onClick={() => {
                // if (
                //   !user?.subscription?.plan ||
                //   user?.subscription?.plan === "Freemium"
                // ) {
                //   openUpgradeModal(
                //     `El envío de documentos por correo está disponible en los planes Empresa y Parnerus. Si quieres continuar con la versión ${user?.subscription?.plan} puedes seguir disfrutando nuestras otras herramientas.`
                //   );
                //   return;
                // }
                sendQuoteHandler();
              }}
            >
              <Iconify icon="tabler:send" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton size="small" onClick={() => openQuoteEditSlider(quote)}>
              <Iconify icon="material-symbols:edit-sharp" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton
              size="small"
              onClick={() => handleOpenConfirm()}
              color="error"
            >
              <Iconify icon="ph:trash-bold" />
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => createInvoiceHandler()}
            size="small"
            disabled={isLoading}
          >
            Factura
          </Button>
        </Stack>
      </Stack>

      <input ref={fileInputRef} type="file" style={{ display: "none" }} />

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar"
        content="¿Estás seguro que deseas eliminar este registro? Esto deshabilitará el registro, y sólo quedará visible para los registros que estén relacionado al mismo, pero no podrás encontrarlo más en este listado"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(quote?.quoteId)}
          >
            Eliminar
          </Button>
        }
      />
    </>
  );
}
