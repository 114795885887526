import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axiosInstance from "../../utils/axios";
import { createOrder } from "../../api/parnerus/services/order";
import { useSharedContext } from "../SharedComponentsContext";
import { getDeal } from "../../api/bitrix/deal";
import { Status } from "../../api/bitrix/types/status";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { useAuthContext } from "../../auth/useAuthContext";
import Iconify from "../../components/iconify";

interface Props extends DialogProps {
  onClose: VoidFunction;
}

export default function PaymentModal({ onClose, open }: Props) {
  const { account } = useAuthContext();
  const { proposedOrder, openOrderViewSlider, triggerLoading } =
    useSharedContext();
  const { listFields, productsList } = useBitrixContext();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
  );
  const [clientSecret, setClientSecret] = useState<string>("");

  const handleCreateOrder = async () => {
    if (proposedOrder) {
      const dealId = await createOrder(proposedOrder);
      await axiosInstance.post("/api/order/insert", {
        dealId,
        accountId: account?.accountId,
        files: proposedOrder.files,
      });

      triggerLoading();
      onClose();
      openOrderViewSlider(
        await getDeal(
          dealId,
          listFields.find((f) => f.FIELD_NAME === "DEAL_STAGE")
            ?.LIST as Status[],
          listFields.find((f) => f.FIELD_NAME === "SMART_INVOICE_STAGE_7")
            ?.LIST as Status[]
        )
      );
    }
  };

  useEffect(() => {
    if (proposedOrder) {
      // Injected code for avoiding payment step
      handleCreateOrder();
      // Ending Injected code for avoiding payment step
      axiosInstance
        .post("/api/payment/create-checkout-session", {
          orderProducts: proposedOrder.products,
          bitrixProducts: productsList,
        })
        .then((response) => setClientSecret(response.data.clientSecret));
    } else {
      setClientSecret("");
    }
  }, [proposedOrder]);

  if (!proposedOrder || proposedOrder) {
    //Condition original before injecting code !proposedOrder
    return <></>;
  }

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle textAlign="center">
          {" "}
          Pago de la Orden{" "}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="eva:close-fill" width={24} height={24} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ overflow: "unset" }}>
          <Stack
            spacing={3}
            marginBottom={3}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
          >
            <div id="checkout" style={{ width: "100%" }}>
              {clientSecret && (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    onComplete: () => handleCreateOrder(),
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
