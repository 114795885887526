import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Button, MenuItem, Stack } from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { useNavigate } from "react-router-dom";
import { useSharedContext } from "../../../shared/SharedComponentsContext";
import { useAuthContext } from "../../../auth/useAuthContext";
import useResponsive from "../../../hooks/useResponsive";

// ----------------------------------------------------------------------

export default function ShorcutsPopover() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const {
    openOrderCreateSlider,
    openInvoiceCreateSlider,
    openQuoteCreateSlider,
    openUpgradeModal,
  } = useSharedContext();

  const isMobile = useResponsive("down", "sm");

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      {!isMobile ? (
        <IconButtonAnimate
          color={openPopover ? "primary" : "default"}
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.focusOpacity
                ),
            }),
          }}
        >
          <Iconify icon="material-symbols:add-box-rounded" />
        </IconButtonAnimate>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenPopover}
          sx={{
            minWidth: 40,
            width: 40,
            height: 40,
            padding: 0,
            borderRadius: "50%",
          }}
        >
          <Iconify icon="mdi:add" sx={{ color: "#D6E4FF" }} />
        </Button>
      )}

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{
          width: isMobile ? "100%" : 200,
          ml: isMobile ? -0.1 : 0,
          mt: isMobile ? -5 : 0,
          p: 0,
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem
            key={"Nueva orden"}
            onClick={() => {
              handleClosePopover();
              openOrderCreateSlider();
            }}
          >
            <Iconify icon="mdi:briefcase-plus" /> Nueva orden
          </MenuItem>
          <MenuItem
            key={"Nueva cotización"}
            onClick={() => {
              handleClosePopover();
              openQuoteCreateSlider();
            }}
          >
            <Iconify icon="fluent:receipt-20-filled" /> Nueva cotización
          </MenuItem>
          <MenuItem
            key={"Nueva factura"}
            onClick={() => {
              handleClosePopover();
              openInvoiceCreateSlider();
            }}
          >
            <Iconify icon="iconamoon:invoice-fill" /> Nueva factura
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
