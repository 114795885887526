import { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Divider,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
// @mui
import { useTheme } from "@mui/material/styles";
// utils
import { fTimestamp } from "../../../../utils/formatTime";
// components
import Label from "../../../../components/label";
import Scrollbar from "../../../../components/scrollbar";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../../components/table";
// sections
import QuoteAnalytic from "../../../../sections/@dashboard/invoice/InvoiceAnalytic";
import { dbQuote } from "../../../../api/parnerus/types/quote";
import axiosInstance from "../../../../utils/axios";
import { format } from "date-fns";
import QuoteTableToolbar from "./QuoteTableToolbar";
import QuoteTableRow from "./QuoteTableRow";
import { useAuthContext } from "../../../../auth/useAuthContext";
import axios from "../../../../utils/axios";
import { useSharedContext } from "../../../../shared/SharedComponentsContext";
import useResponsive from "../../../../hooks/useResponsive";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "inoiceNumber", label: "# Cotización", align: "left" },
  { id: "client", label: "Cliente", align: "left" },
  { id: "createDate", label: "Creado", align: "left" },
  { id: "dueDate", label: "Vencimiento", align: "left" },
  { id: "price", label: "Total", align: "center", width: 140 },
  { id: "status", label: "Estado", align: "left" },
  { id: "" },
];

// ----------------------------------------------------------------------

export enum QuotesTableVariants {
  Default,
  ContactAndCompany,
}

type QuoteTableFilter = {
  field: "contactId" | "companyId";
  value: string;
};

interface QuotesTableProps {
  variant: QuotesTableVariants;
  filterAdded?: QuoteTableFilter;
}

export default function QuotesTable({
  variant,
  filterAdded,
}: QuotesTableProps) {
  const { account } = useAuthContext();
  const { loadingTrigger } = useSharedContext();
  const isMobile = useResponsive("down", "sm");
  const theme = useTheme();

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<dbQuote[]>([]);

  const [filterName, setFilterName] = useState("");

  const [filterStatus, setFilterStatus] = useState("all");

  const [filterService, setFilterService] = useState("all");

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isFiltered =
    filterStatus !== "all" ||
    filterName !== "" ||
    filterService !== "all" ||
    (!!filterStartDate && !!filterEndDate);

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterService) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const getLengthByStatus = (status: string) =>
    tableData.filter((item) => item.status === status).length;

  const getTotalPriceByStatus = (status: string) =>
    tableData
      .filter((item) => item.status === status)
      .reduce(
        (prev, current) =>
          prev + current.products.reduce((p, c) => p + c.total, 0),
        0
      );

  const getPercentByStatus = (status: string) =>
    (getLengthByStatus(status) / tableData.length) * 100;

  const getLengthByOverdue = () =>
    tableData.filter((item) => item.dueDate < format(new Date(), "yyyy-MM-dd"))
      .length;

  const getTotalPriceByOverdue = () =>
    tableData
      .filter((item) => item.dueDate < format(new Date(), "yyyy-MM-dd"))
      .reduce(
        (prev, current) =>
          prev + current.products.reduce((p, c) => p + c.total, 0),
        0
      );

  const getPercentByOverdue = () =>
    (getLengthByOverdue() / tableData.length) * 100;

  const TABS = [
    { value: "all", label: "All", color: "info", count: tableData.length },
    {
      value: "Accepted",
      label: "Accepted",
      color: "success",
      count: getLengthByStatus("Accepted"),
    },
    {
      value: "Sent to customer",
      label: "Sent to customer",
      color: "warning",
      count: getLengthByStatus("Sent to customer"),
    },
    {
      value: "Declined",
      label: "Declined",
      color: "error",
      count: getLengthByStatus("Declined"),
    },
    {
      value: "New",
      label: "New",
      color: "default",
      count: getLengthByStatus("New"),
    },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("all");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  const handleDeleteRow = async (id: string | undefined) => {
    await axios.post("/api/quote/disable", {
      quoteId: id,
    });

    const deleteRow = tableData.filter((row) => row.quoteId !== id);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  useEffect(() => {
    setRowsPerPage(25);

    axiosInstance
      .get(`/api/quote/list?accountId=${account?.accountId}`)
      .then((response) => {
        if (filterAdded) {
          setTableData(
            response.data.results.filter(
              (quote: dbQuote) => quote[filterAdded.field] === filterAdded.value
            )
          );
        } else {
          setTableData(response.data.results);
        }
        setIsLoading(false);
      });
  }, [loadingTrigger]);

  return (
    <>
      {variant === QuotesTableVariants.Default && !isMobile && (
        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 2 }}
            >
              <QuoteAnalytic
                title="Total"
                elementName="Cotizaciones"
                total={tableData.length}
                percent={100}
                price={tableData.reduce(
                  (prev, current) =>
                    prev + current.products.reduce((p, c) => p + c.total, 0),
                  0
                )}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
              />

              <QuoteAnalytic
                title="Accepted"
                elementName="Cotizaciones"
                total={getLengthByStatus("Accepted")}
                percent={getPercentByStatus("Accepted")}
                price={getTotalPriceByStatus("Accepted")}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />

              <QuoteAnalytic
                title="Sent"
                elementName="Cotizaciones"
                total={getLengthByStatus("Sent to customer")}
                percent={getPercentByStatus("Sent to customer")}
                price={getTotalPriceByStatus("Sent to customer")}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
              />

              <QuoteAnalytic
                title="Declined"
                elementName="Cotizaciones"
                total={getLengthByStatus("Declined")}
                percent={getPercentByStatus("Declined")}
                price={getTotalPriceByStatus("Declined")}
                icon="eva:bell-fill"
                color={theme.palette.error.main}
              />

              <QuoteAnalytic
                title="New"
                elementName="Cotizaciones"
                total={getLengthByStatus("New")}
                percent={getPercentByStatus("New")}
                price={getTotalPriceByStatus("New")}
                icon="eva:file-fill"
                color={theme.palette.text.secondary}
              />
            </Stack>
          </Scrollbar>
        </Card>
      )}

      <Card>
        {variant === QuotesTableVariants.Default && !isMobile && (
          <>
            <Tabs
              value={filterStatus}
              onChange={handleFilterStatus}
              sx={{
                px: 2,
                bgcolor: "background.neutral",
              }}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  icon={
                    <Label color={tab.color} sx={{ mr: 1 }}>
                      {tab.count}
                    </Label>
                  }
                />
              ))}
            </Tabs>

            <Divider />
          </>
        )}

        <QuoteTableToolbar
          isFiltered={isFiltered}
          filterName={filterName}
          filterEndDate={filterEndDate}
          onFilterName={handleFilterName}
          onResetFilter={handleResetFilter}
          filterStartDate={filterStartDate}
          onFilterStartDate={(newValue) => {
            setFilterStartDate(newValue);
          }}
          onFilterEndDate={(newValue) => {
            setFilterEndDate(newValue);
          }}
          variant={variant}
        />

        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom
                //order={order}
                //orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                //onSort={onSort}
              />

              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>Cargando información...</TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <QuoteTableRow
                        key={row.quoteId}
                        row={row}
                        onSelectRow={() => console.log("onSelectRow(row.id)")}
                        onViewRow={() => console.log("handleViewRow(row.id)")}
                        onEditRow={() => console.log("handleEditRow(row.id)")}
                        onDeleteRow={() => handleDeleteRow(row.quoteId)}
                      />
                    ))}

                  <TableEmptyRows
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              )}
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}: {
  inputData: dbQuote[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterService: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (quote) =>
        (quote.companyName &&
          quote.companyName.toLowerCase().indexOf(filterName.toLowerCase()) !==
            -1) ||
        (quote.contactName &&
          quote.contactName.toLowerCase().indexOf(filterName.toLowerCase()) !==
            -1) ||
        quote.quoteId.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    inputData = inputData.filter((quote) => quote.status === filterStatus);
  }

  // if (filterService !== "all") {
  //   inputData = inputData.filter((quote) =>
  //     quote.items.some((c) => c.service === filterService)
  //   );
  // }

  if (filterStartDate && filterEndDate) {
    inputData = inputData.filter(
      (quote) =>
        fTimestamp(quote.createdDate) >= fTimestamp(filterStartDate) &&
        fTimestamp(quote.createdDate) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}
