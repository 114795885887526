import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// @mui
import { Box, Stack } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import { useSettingsContext } from "../../components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import NavHorizontal from "./nav/NavHorizontal";
import FixedBottomNavigation from "./bottomNavigation";
import Logo from "../../components/logo";
import { IconButtonAnimate } from "../../components/animate";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "lg");
  const isMobile = useResponsive("down", "sm");

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: "flex" },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        <>
          <Logo
            isExpanded
            sx={{
              zIndex: 9,
              position: "absolute",
              mt: { xs: 1.5, md: 5 },
              ml: { xs: 2, md: 5 },
            }}
          />
          {/* <IconButtonAnimate
            color="default"
            onClick={() => navigate("/dashboard/info")}
            sx={{
              padding: 0,
              position: "absolute",
              right: 24,
            }}
          >
            <Iconify icon="eva:bell-fill" />
          </IconButtonAnimate> */}
        </>
      ) : (
        <Header onOpenNav={handleOpen} />
      )}

      <Box
        sx={{
          display: { lg: "flex" },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>

      {isMobile && <FixedBottomNavigation onOpenNav={handleOpen} />}
    </>
  );
}
