import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Iconify from "../../../components/iconify";
import NotificationsPopover from "../header/NotificationsPopover";
import ShorcutsPopover from "../header/ShortcutsPopover";
import HelpPopover from "../header/HelpPopover";
import AccountPopover from "../header/AccountPopover";
import { IconButton, Paper } from "@mui/material";

type Props = {
  onOpenNav?: VoidFunction;
};

export default function FixedBottomNavigation({ onOpenNav }: Props) {
  //   const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          //   value={value}
          //   onChange={(event, newValue) => {
          //     setValue(newValue);
          //   }}
        >
          <BottomNavigationAction
            label="Menú"
            icon={
              <IconButton
                onClick={onOpenNav}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButton>
            }
          />
          <BottomNavigationAction
            label="Notificaciones"
            icon={<NotificationsPopover />}
          />
          <BottomNavigationAction icon={<ShorcutsPopover />} />
          <BottomNavigationAction label="Contacto" icon={<HelpPopover />} />
          <BottomNavigationAction label="Perfil" icon={<AccountPopover />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
