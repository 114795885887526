import { useState, useRef, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
// components
import Scrollbar from "../../components/scrollbar";
//
import { format } from "date-fns";
import InvoiceViewToolbar from "./InvoiceViewToolbar";
import { useSharedContext } from "../SharedComponentsContext";
import axiosInstance from "../../utils/axios";
import { dbContact } from "../../api/parnerus/types/contact";
import Iconify from "../../components/iconify";
import { dbCompany } from "../../api/parnerus/types/company";
import { dbQuote } from "../../api/parnerus/types/quote";
import OrdersTable, { OrdersTableVariants } from "../../pages/Desk/OrdersTable";
import InvoiceDocument from "./InvoiceDocument";
import ReactPDF from "@react-pdf/renderer";
import { useAuthContext } from "../../auth/useAuthContext";
import { useBitrixContext } from "../../api/bitrix/context/BitrixContext";
import { dbAccount } from "../../api/parnerus/types/account";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledLabel = styled("span")(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contact-tabpanel-${index}`}
      aria-labelledby={`contact-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {
  isOpen: boolean;
};

export default function InvoiceDetails({ isOpen }: Props) {
  const { account } = useAuthContext();
  const { closeInvoiceViewSlider } = useSharedContext();
  const { productsList } = useBitrixContext();
  const { user } = useAuthContext();
  const {
    invoice,
    openContactViewSlider,
    openCompanyViewSlider,
    openQuoteViewSlider,
  } = useSharedContext();
  const isMobile = useResponsive("down", "sm");
  const [contact, setContact] = useState<dbContact>();
  const [company, setCompany] = useState<dbCompany>();
  const [quote, setQuote] = useState<dbQuote>();

  const [tabSelected, setTabSelected] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [liked, setLiked] = useState(false);

  const [completed, setCompleted] = useState(true);

  const handleLiked = () => {
    setLiked(!liked);
  };

  const handleCompleted = () => {
    setCompleted(!completed);
  };

  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (invoice && invoice.invoiceId) {
      if (invoice?.contactId) {
        axiosInstance
          .get(
            `/api/contact/list-by-id?contactId=${invoice.contactId}&accountId=${account?.accountId}`
          )
          .then((response) => setContact(response.data.results[0]));
      }

      if (invoice?.companyId) {
        axiosInstance
          .get(
            `/api/company/list-by-id?companyId=${invoice.companyId}&accountId=${account?.accountId}`
          )
          .then((response) => setCompany(response.data.results[0]));
      }

      if (invoice?.quoteId) {
        axiosInstance
          .get(`/api/quote/list-by-id?quoteId=${invoice.quoteId}`)
          .then((response) => {
            if (response.data.results.length) {
              setQuote(response.data.results[0]);
            }
          });
      } else {
        setQuote(undefined);
      }
    }
  }, [invoice]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeInvoiceViewSlider}
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: 1,
              sm: 900,
            },
          },
        }}
      >
        <InvoiceViewToolbar
          taskName={invoice?.invoiceId || ""}
          fileInputRef={fileInputRef}
          liked={liked}
          completed={completed}
          onLike={handleLiked}
          onAttach={handleClickAttach}
          onCompleted={handleCompleted}
          onCloseDetails={closeInvoiceViewSlider}
          contact={contact}
          company={company}
        />

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, pt: 3, pb: 5 }}>
            <Tabs
              value={tabSelected}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Información general" />
              {!isMobile && <Tab label="Visualizar documento" />}
              <Tab label="Órdenes" />
            </Tabs>

            <CustomTabPanel value={tabSelected} index={0}>
              <Stack spacing={3}>
                <Typography variant="overline">Cliente</Typography>

                <Stack direction={isMobile ? "column" : "row"} gap={3}>
                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Contacto
                    </StyledLabel>
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      onClick={() => openContactViewSlider(contact)}
                      sx={{ cursor: "pointer", textTransform: "capitalize" }}
                    >
                      {invoice?.contactName}
                      <Iconify icon="ph:link-light" />
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={200}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Compañía
                    </StyledLabel>

                    {invoice?.companyName ? (
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                        onClick={() => openCompanyViewSlider(company)}
                        sx={{ cursor: "pointer", textTransform: "capitalize" }}
                      >
                        {invoice?.companyName}
                        <Iconify icon="ph:link-light" />
                      </Stack>
                    ) : (
                      "-"
                    )}
                  </Stack>
                </Stack>

                <Typography variant="overline" sx={{ pt: 3 }}>
                  Información
                </Typography>

                <Stack direction={isMobile ? "column" : "row"} gap={3}>
                  <Stack direction="column" width={270}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Cotización
                    </StyledLabel>

                    {quote ? (
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                        onClick={() => openQuoteViewSlider(quote)}
                        sx={{ cursor: "pointer", textTransform: "capitalize" }}
                      >
                        Cotización #{quote.quoteId.split("-")[0].toUpperCase()}
                        <Iconify icon="ph:link-light" />
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        alignItems="center"
                      >
                        <Typography variant="body2">
                          No hay una cotización relacionada
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack direction="column" width={isMobile ? "100%" : 500}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Comentarios
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      <Typography variant="caption">
                        {invoice?.comments || "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={isMobile ? "column" : "row"} gap={3}>
                  <Stack direction="column" width={500}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Vencimiento
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {invoice && (
                        <Typography variant="caption">
                          {format(new Date(invoice.dueDate), "dd MMM yyyy")}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={500}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Creado el
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {invoice && (
                        <Typography variant="caption">
                          {format(new Date(invoice.createdDate), "dd MMM yyyy")}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>

                  <Stack direction="column" width={500}>
                    <StyledLabel
                      sx={{ height: 10, lineHeight: "10px", my: 0.5 }}
                    >
                      Fecha de cierre
                    </StyledLabel>

                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {invoice && invoice.closeDate ? (
                        <Typography variant="caption">
                          {format(
                            new Date(invoice.closeDate),
                            "dd MMM yyyy HH:mm"
                          )}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider />

                <Typography variant="overline">DETALLES</Typography>

                <Stack direction="column">
                  <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                    Productos
                  </StyledLabel>

                  <Stack direction="row" flexWrap="wrap" alignItems="center">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descr.</TableCell>
                          <TableCell>Cant.</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice?.products.map((product) => (
                          <TableRow key={product.id}>
                            <TableCell>
                              <Typography variant="overline">
                                {product.product}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                ${product.price}(Parnerus{" "}
                                {
                                  productsList.find((p) => p.ID === product.id)
                                    ?.PRICE
                                }
                                )<b>x</b>
                                {product.quantity}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                $
                                {Number(product.price) *
                                  Number(product.quantity)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Stack>
                </Stack>

                <Stack direction="column">
                  <StyledLabel sx={{ height: 10, lineHeight: "10px", my: 0.5 }}>
                    Importe total
                  </StyledLabel>

                  <Stack direction="row" flexWrap="wrap" alignItems="center">
                    ${" "}
                    {invoice?.products.reduce(
                      (prev, current) => prev + current.total,
                      0
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </CustomTabPanel>

            {!isMobile && (
              <CustomTabPanel value={tabSelected} index={1}>
                {invoice && (
                  <Stack>
                    <ReactPDF.PDFViewer height={600}>
                      <InvoiceDocument
                        invoice={invoice}
                        user={user}
                        contact={contact}
                        company={company}
                        account={account as dbAccount}
                      />
                    </ReactPDF.PDFViewer>
                  </Stack>
                )}
              </CustomTabPanel>
            )}

            <CustomTabPanel value={tabSelected} index={isMobile ? 1 : 2}>
              <Stack spacing={3}>
                <OrdersTable
                  variant={OrdersTableVariants.Contact}
                  filtersAdded={[
                    {
                      field: "UF_CRM_1691018773128",
                      value: invoice?.invoiceId || "99999999",
                    },
                  ]}
                />
              </Stack>
            </CustomTabPanel>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
